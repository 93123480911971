<template>
  <d-confirm-dialog
    :title="title"
    :value="dialog"
    :status="status"
    @confirm="onClickDelete"
    @cancel="closeDialog"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

const props = {
  sensorType: {
    type: String,
    default: 'radar'
  },
  sensor: {
    type: Object,
    default: () => ({
      name: null
    })
  },
  dialog: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'DeleteSensorDialog',
  props,
  computed: {
    ...mapGetters('sensors', ['status', 'error']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('sites', ['activeSiteId']),
    title() {
      return `Delete ${(this.sensor && this.sensor.name) || 'recording'} ?`
    }
  },
  methods: {
    ...mapActions('sensors', {
      deleteCompass: 'DELETE_GPS_COMPASS',
      deleteDiscovair: 'DELETE_DISCOVAIR_SENSOR'
    }),
    ...mapActions('cameras', {deleteCamera: 'DELETE_CAMERA',}),
    ...mapActions('rf_sensors', {deleteRfSensor: 'DELETE_RF_SENSOR',}),
    ...mapActions('cannons', {deleteCannon: 'DELETE_CANNON',}),
    ...mapActions('radars', { deleteRadar: 'DELETE_RADAR' }),
    ...mapActions('plans', {
      deleteSensor: 'DELETE_SENSOR'
    }),
    ...mapActions('recordings', {deleteRecording: 'DELETE_RECORDING'}),
    async onClickDelete() {
      const { sensor, sensorType } = this
      if (this.activePlan) this.deleteSensor(sensor)
      else
        switch (sensorType) {
          case 'radar':
            await this.deleteRadar({
              siteId: this.activeSiteId,
              radar: sensor
            })
            break
          case 'rf':
            await this.deleteRfSensor({siteId: this.activeSiteId, rf_sensor: sensor, dsx: false})
            break
          case 'cannon':
            await this.deleteCannon({siteId: this.activeSiteId, cannon: sensor})
            break
          case 'compass':
            await this.deleteCompass(sensor)
            break
          case 'camera':
            await this.deleteCamera({siteId: this.activeSiteId, camera: sensor})
            break
          case 'recording':
            await this.deleteRecording(sensor)
            break
          case 'discovair':
            await this.deleteDiscovair(sensor)
            break
          case 'dronesentryx':
            await this.deleteRfSensor({siteId: this.activeSiteId, rf_sensor: sensor, dsx: true})
            break
        }
      this.$emit('onDelete')
      return this.closeDialog()
    },
    closeDialog() {
      return this.$emit('onClose')
    }
  },
  watch: {
    dialog() {
      this.$store.commit('sensors/SET_ERROR', null)
      this.$store.commit('sensors/SET_STATUS', null)
    }
  }
}
</script>

<style scoped>
.v-btn {
  min-width: 0;
}
</style>
